import { INPUT_NUMBER_MAX_VALUE } from "constants.js";
import uniq from "lodash/uniq";
import { pickLabel } from "./PriceForm/utils";

type FieldType = "text" | "number" | "select" | "price" | "formatted-number";

export type DynamicField<T> = {
  label: string;
  name: string;
  type: FieldType;
  min: T;
  max: T;
  devaultValue?: T;
  value?: T;
  options?: T[];
};

type FieldDescription = {
  label?: string;
  placeHolder?: string;
  name: string;
  type?: FieldType;
  isOutput?: boolean;
  min?: number;
  max?: number;
};

const barrierLevelFieldDescription: FieldDescription = {
  label: "Barrier Level",
  placeHolder: "Barrier Level",
  name: "barrierLevel",
  type: "number",
  isOutput: true,
};

const accumulationLevelFieldDescription: FieldDescription = {
  label: "Accumulation Level",
  placeHolder: "Accumulation Level",
  name: "accumulationLevel",
  type: "number",
  isOutput: true,
  min: 0,
};

const priceFieldDescription: FieldDescription = {
  label: "Price",
  placeHolder: "Price",
  name: "price",
  type: "price",
  isOutput: true,
  min: 0,
};

const remainderLevelDescription = {
  label: "Remainder Level",
  placeHolder: "Remainder Level",
  name: "remainderLevel",
  type: "number",
  isOutput: true,
  min: 0,
  max: INPUT_NUMBER_MAX_VALUE,
};

const PRICING_SUB_FORM_NAME_PRICING = "price";

export const requiredField = (value) => (value ? undefined : false); // Empty error message

const CEDD = {
  forms: [
    {
      name: PRICING_SUB_FORM_NAME_PRICING,
      label: "Price",
      input: [
        {
          ...barrierLevelFieldDescription,
          validate: requiredField,
        },
        {
          ...accumulationLevelFieldDescription,
          validate: requiredField,
        },
        {
          ...remainderLevelDescription,
          validate: requiredField,
        },
      ],
      output: {
        ...priceFieldDescription,
        type: "formatted-number",
      },
    },
  ],
};

const P_DD = {
  forms: [
    {
      name: PRICING_SUB_FORM_NAME_PRICING,
      label: "Price",
      input: [
        {
          ...accumulationLevelFieldDescription,
          validate: requiredField,
        },
        {
          ...remainderLevelDescription,
          validate: requiredField,
        },
      ],
      output: {
        ...priceFieldDescription,
      },
    },
  ],
};

export const BARRIER_TYPE_EUROPEAN = "European";
export const BARRIER_TYPE_AMERICAN = "American";
export const BARRIER_TYPE_NONE = "None";

export const BARRIER_TYPE_LABELS = {
  [BARRIER_TYPE_EUROPEAN]: "Daily KO",
};

export const structuresConfig = {
  [BARRIER_TYPE_AMERICAN]: CEDD,
  [BARRIER_TYPE_EUROPEAN]: CEDD,
  [BARRIER_TYPE_NONE]: P_DD,
};

export const pickBarrierTypeLabel = (label) =>
  pickLabel(label, BARRIER_TYPE_LABELS);

const structuresConfigFields: string[] = [];

Object.entries(structuresConfig).forEach((item) => {
  const forms = item[1].forms;
  const inputs = forms[0].input;
  inputs.forEach(({ name }) => {
    structuresConfigFields.push(name);
  });

  const outputName = forms[0].output.name;
  structuresConfigFields.push(outputName);
});

export const structuresConfigInputFielsUniq = uniq(structuresConfigFields);
