import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { change } from 'redux-form';
import {
  PRICING_HISTORY_SELECT_TRAIL_FROM_LOADED_DATA,
  PRICING_HISTORY_SET_TRAIL,
  setTrailData,
  preSetTrailData,
  PRICING_HISTORY_PRE_SET_TRAIL,
  PRICING_HISTORY_SELECT_TRAIL_FORM_RESTORE_TRIGGER,
} from './pricingHistoryActions';
import {
  PRICING_FORM,
  selectSubForm,
  pricingDataLoader,
  setCahrtData
} from 'redux/actions/price';
import { stopSubmit } from 'redux-form';
import { chooseStructureConfig } from '../../queries/price';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { catchError, switchMap, map, delay, take, debounceTime } from 'rxjs/operators';
import { STRUCTURE_ACCUMULATOR } from 'constants.js';
import { scrollToStartAction } from 'redux/actions/common';
import { badSelectTrailAction, selectedTrailLoadedDataAction } from 'redux/prices/history/pricingHistoryActions';
import { updateTrailDataAfterRequest } from 'redux/prices/history/pricingHistoryEpics';
import { getAccumulatorByTag } from 'pages/price/PricingTag/query';
import { PRICING_TAG_FORM } from 'pages/price/PricingTag/PricingTagForm';
import { isPricingInActive } from 'pages/price/PricingHistory/PricingHistoryItem';

export const selectPricingHistoryActionResolver = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_HISTORY_SELECT_TRAIL_FROM_LOADED_DATA),
      switchMap(({ trail }) => {
        const currentStructure = priceFormValueSelector(state$.value).structure;

        if (trail.structureName === currentStructure) {
          return of(setTrailData(trail));
        }

        return from([
          pricingDataLoader(true),
          change(PRICING_FORM, 'structure', trail.structureName),
          preSetTrailData(trail)
        ]);
      })
    );

export const preSelectPricingHistoryAction = action$ =>
  action$
    .pipe(
      ofType(PRICING_HISTORY_PRE_SET_TRAIL),
      map(({ payload }) => payload),
      switchMap((trail) => {
        const structureConfig = chooseStructureConfig(trail.structureName);
        return action$.pipe(
          ofType(structureConfig.dataLoaded),
          take(1),
          delay(50), // @TODO: remove
          map(() => setTrailData(trail)),
        )
      })
    );


const fillFields = [
  'notional',
  'barrierType',
  'leverageValue',
  'leverageStyle',
  'structureType',
  'remainderFeature',
];

export const selectPricingHistoryAction = action$ =>
  action$
    .pipe(
      ofType(PRICING_HISTORY_SET_TRAIL),
      map(({ payload }) => payload),
      delay(50),
      switchMap((trail) => {
        const structureConfig = chooseStructureConfig(trail.structureName);
        let calculateType = trail && trail.calculateType ? trail.calculateType : 'strikeLevel';
        if (trail && trail.calculateType === 'strikeLevel' && trail.structureName === STRUCTURE_ACCUMULATOR) {
          calculateType = 'price';
        }
        const actions = [
          // reset(PRICING_FORM),
          scrollToStartAction(),
          ...structureConfig.limitation
            .filter(({ filled }) => filled)
            .filter((fieldConfig) => typeof fieldConfig.sheetExtractor === 'function')
            .map((fieldConfig) => {
              return change(PRICING_FORM, fieldConfig.key, fieldConfig.sheetExtractor(trail));
            }),

          ...fillFields
            .map(field => change(PRICING_FORM, field, trail[field])),

          selectSubForm(calculateType),
        ];
        return from(actions);
      })
    );

export const fillDynamicFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_HISTORY_SET_TRAIL),
    delay(150), //@TODO: remove delay
    map(({ payload }) => payload),
    switchMap((trail) => {
      const { price: { currentFormConfig } } = state$.value;

      const actions = [
        pricingDataLoader(false),
        change(PRICING_FORM, 'expiration', trail.expiryDate)   // Set Structure Expiry Date change trigger after set default value from Underlying
      ];

      if (currentFormConfig && currentFormConfig.input) {
        const chart = {};
        currentFormConfig.input
          .forEach(({ name }) => {
            const value = trail.remainderFeature === 'none' && name === 'remainderLevel' ? null : trail[name];
            actions.push(change(PRICING_FORM, name, value));
            chart[name] = trail[name];
          });

        actions.push(setCahrtData(chart));
      }

      return from(actions);
    })
  );

const triggerRestoreTag = action$ =>
  action$.pipe(
    ofType(PRICING_HISTORY_SELECT_TRAIL_FORM_RESTORE_TRIGGER),
    map(({ payload }) => payload),
    debounceTime(250),
    switchMap(
      (tag) => from(getAccumulatorByTag(tag))
        .pipe(
          switchMap((data) => {
            const structureConfig = chooseStructureConfig(data.structure);
            const trail = structureConfig.prepareteResponse(data);
            const actions = [];

            const formatedTrail = updateTrailDataAfterRequest(trail);
            if (!isPricingInActive(formatedTrail)) {
              actions.push(selectedTrailLoadedDataAction(formatedTrail));
            } else {
              actions.push(badSelectTrailAction(formatedTrail));
            }

            return from(actions)
          }),
          catchError(() => {
            return from([stopSubmit(PRICING_TAG_FORM, { _error: [{ code: 'E54' }] })]);
          })
        )
    )
  )

export default combineEpics(
  selectPricingHistoryActionResolver,
  preSelectPricingHistoryAction,
  selectPricingHistoryAction,
  fillDynamicFormEpic,
  triggerRestoreTag,
);
