import ArrowBack from '@material-ui/icons/ArrowBack';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import PricingHistoryListEmpty from './PricingHistoryListEmpty';

interface EmptyPricingHistoryProps {
  onClose: () => void;
}

const EmptyPricingHistory: React.FC<EmptyPricingHistoryProps> = ({
  onClose,
}) => {
  return (
    <div className='pricing-history-list'>
      <div className='pricing-history-title'>
        <ArrowBack className='icon' onClick={onClose} />
        <FormattedMessage
          id='structured-products-history'
          defaultMessage='Structured Products History'
        />
      </div>
      <div className='pricing-history-list-empty'>
        <PricingHistoryListEmpty />
        <Button color='default' onClick={onClose}>
          <FormattedMessage
            id='price.page.go-to-pricing'
            defaultMessage='Go to Pricing'
          />
        </Button>
      </div>
    </div>
  );
};

export default EmptyPricingHistory;
