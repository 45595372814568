// @TODO: move to new file
export const REDUX_FORM_FIELD_CHANGE = '@@redux-form/CHANGE';

// Fields
export const REDUX_FORM_FIELD_FOCUS = '@@redux-form/FOCUS';
export const REDUX_FORM_FIELD_BLUR = '@@redux-form/BLUR';
export const REDUX_FORM_FIELD_UNREGISTER = '@@redux-form/UNREGISTER_FIELD';
export const REDUX_FORM_FIELD_REGISTER = '@@redux-form/REGISTER_FIELD';

// Form
export const REDUX_FORM_RESET = '@@redux-form/RESET';
export const REDUX_FORM_DESTROY = '@@redux-form/DESTROY';
export const REDUX_FORM_INITIALIZE = '@@redux-form/INITIALIZE';
export const REDUX_FORM_SUBMITTED = '@@redux-form/SET_SUBMIT_SUCCEEDED';
export const REDUX_FORM_UPDATE_SYNC_ERRORS = '@@redux-form/UPDATE_SYNC_ERRORS';

export const STRUCTURED_PRODUCTS_SUBMIT_DEBOUNCE = 400;

export const CARD_CONTRACT_ACTIONS = {
  ADD: 'add',
  UPDATE: 'update',
  DELETE: 'delete'
}

export const PREMIUM_AMOUNT_FIELD = 'premiumAmount';