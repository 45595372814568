const STRUCTURED_PRODUCTS = "@STRUCTURED_PRODUCTS";
export const STRUCTURED_PRODUCTS_FORM_CONFIG_SET = `${STRUCTURED_PRODUCTS}/FORM/CONFIG/SET`;
export const STRUCTURED_PRODUCTS_FORM_OPTIONS_SET = `${STRUCTURED_PRODUCTS}/FORM/OPTIONS/SET`;
export const STRUCTURED_PRODUCTS_FORM_OPTIONS_DEFAULT = `${STRUCTURED_PRODUCTS}/FORM/OPTIONS/DEFAULT`;
export const STRUCTURED_PRODUCTS_RESET = `${STRUCTURED_PRODUCTS}/STATE/RESET`;
export const STRUCTURED_PRODUCTS_FORM_LOAD = `${STRUCTURED_PRODUCTS}/FORM/LOAD`;
export const STRUCTURED_PRODUCTS_FORM_DESTROY = `${STRUCTURED_PRODUCTS}/FORM/DESTROY`;
export const STRUCTURED_PRODUCTS_FORM_DATA_LOADED = `${STRUCTURED_PRODUCTS}/FORM/DATA_LOADED`;
export const STRUCTURED_PRODUCTS_FORM_SUBMIT = `${STRUCTURED_PRODUCTS}/FORM/SUBMIT`;
export const STRUCTURED_PRODUCTS_FORM_LOADER = `${STRUCTURED_PRODUCTS}/FORM/LOADER`;
export const STRUCTURED_PRODUCTS_FORM_FIELD_UPDATE = `${STRUCTURED_PRODUCTS}/FORM/FIELD/UPDATE`;
export const STRUCTURED_PRODUCTS_FORM_FIELDS_SET = `${STRUCTURED_PRODUCTS}/FORM/FIELDS/SET`;
export const STRUCTURED_PRODUCTS_STRUCTURE_SET = `${STRUCTURED_PRODUCTS}/STRUCTURE/SET`;
export const STRUCTURED_PRODUCTS_FORM_RESTORE = `${STRUCTURED_PRODUCTS}/FORM/RESTORE`;
export const STRUCTURED_PRODUCTS_FORM_VALUES_RESTORE = `${STRUCTURED_PRODUCTS}/FORM_VALUES/RESTORE`;
export const STRUCTURED_PRODUCTS_FORM_RESTORE_TRIGGER = `${STRUCTURED_PRODUCTS}/FORM/RESTORE/TRIGGER`;
export const STRUCTURED_PRODUCTS_FORM_RESTORE_START = `${STRUCTURED_PRODUCTS}/FORM/RESTORE/START`;
export const STRUCTURED_PRODUCTS_FORM_RESTORE_FINISH = `${STRUCTURED_PRODUCTS}/FORM/RESTORE/FINISH`;
export const STRUCTURED_PRODUCTS_FORM_RESTORE_CURRENCY_LIST = `${STRUCTURED_PRODUCTS}/FORM/RESTORE/CURRENCY_LIST`;
export const STRUCTURED_PRODUCTS_FORM_RESTORE_PROCESS = `${STRUCTURED_PRODUCTS}/FORM/RESTORE/PROCESS`;
export const STRUCTURED_PRODUCTS_SET_CUSTOM_OPTIONS_INITIAL_VALUES = `${STRUCTURED_PRODUCTS}/CUSTOM_OPTIONS/INITIAL_VALUES/SET`;
export const STRUCTURED_PRODUCTS_SET_FIELD_SETTINGS = `${STRUCTURED_PRODUCTS}/CUSTOM_OPTIONS/FIELD_SETTING/SET`;

export const STRUCTURED_PRODUCTS_SET_FORM_STRUCTURES = `${STRUCTURED_PRODUCTS}/FORM/SET_STRUCTURES`;

const STRUCTURED_PRODUCTS_FORM_RESULTS = `${STRUCTURED_PRODUCTS}/FORM/RESULTS`;
export const STRUCTURED_PRODUCTS_FORM_RESULTS_SET = `${STRUCTURED_PRODUCTS_FORM_RESULTS}/SET`;
export const STRUCTURED_PRODUCTS_FORM_RESULTS_LOADER = `${STRUCTURED_PRODUCTS_FORM_RESULTS}/LOADER`;

const STRUCTURED_PRODUCTS_SCHEDULE = `${STRUCTURED_PRODUCTS}/SCHEDULE`;
export const STRUCTURED_PRODUCTS_SCHEDULE_GET_DATA = `${STRUCTURED_PRODUCTS_SCHEDULE}/GET-DATA`;
export const STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_GET_DATA = `${STRUCTURED_PRODUCTS_SCHEDULE}/OPTIONS/GET-DATA`;
export const STRUCTURED_PRODUCTS_SCHEDULE_SET = `${STRUCTURED_PRODUCTS_SCHEDULE}/SET`;
export const STRUCTURED_PRODUCTS_SCHEDULE_UPDATE = `${STRUCTURED_PRODUCTS_SCHEDULE}/UPDATE`;
export const STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_SET = `${STRUCTURED_PRODUCTS_SCHEDULE}/OPTIONS/SET`;
export const STRUCTURED_PRODUCTS_BANK_HOLIDAYS_SET = `${STRUCTURED_PRODUCTS_SCHEDULE}/BANK_HOLIDAYS/SET`;
export const STRUCTURED_PRODUCTS_BANK_HOLIDAYS_GET = `${STRUCTURED_PRODUCTS_SCHEDULE}/BANK_HOLIDAYS/GET`;
export const STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_UPDATE_ALL_FIELDS = `${STRUCTURED_PRODUCTS_SCHEDULE}/OPTIONS/ALL_FIELDS`;
export const STRUCTURED_PRODUCTS_SCHEDULE_RESPONSE_SET = `${STRUCTURED_PRODUCTS_SCHEDULE}/RESPONSE/SET`;
export const STRUCTURED_PRODUCTS_SCHEDULE_LOADER = `${STRUCTURED_PRODUCTS_SCHEDULE}/LOADER`;
export const STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_LOADER = `${STRUCTURED_PRODUCTS_SCHEDULE}/OPTIONS/LOADER`;
export const STRUCTURED_PRODUCTS_SCHEDULE_LOCK = `${STRUCTURED_PRODUCTS_SCHEDULE}/LOCK`;
export const STRUCTURED_PRODUCTS_SCHEDULE_FROM_HISTORY = `${STRUCTURED_PRODUCTS_SCHEDULE}/FROM/HISTORY`;
export const STRUCTURED_PRODUCTS_SCHEDULE_USER_INPUTS = `${STRUCTURED_PRODUCTS_SCHEDULE}/USER/INPUTS`;
export const STRUCTURED_PRODUCTS_SCHEDULE_USER_INPUTS_RESET = `${STRUCTURED_PRODUCTS_SCHEDULE_USER_INPUTS}/RESET`;
export const STRUCTURED_PRODUCTS_SCHEDULE_INVALID = `${STRUCTURED_PRODUCTS_SCHEDULE}/INVALID`;

const STRUCTURED_PRODUCTS_FROM_UNDERLYINGS = `${STRUCTURED_PRODUCTS}/FORM/UNDERLYINGS`;
export const STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_RESET = `${STRUCTURED_PRODUCTS_FROM_UNDERLYINGS}/RESET`;
export const STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_ADD_EMPTY = `${STRUCTURED_PRODUCTS_FROM_UNDERLYINGS}/ADD_EMPTY`;
export const STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_UPDATE = `${STRUCTURED_PRODUCTS_FROM_UNDERLYINGS}/UPDATE`;
export const STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_DELETE = `${STRUCTURED_PRODUCTS_FROM_UNDERLYINGS}/DELETE`;
export const STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_CREATE_LIST = `${STRUCTURED_PRODUCTS_FROM_UNDERLYINGS}/LIST/CREATE`;
export const STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_UPDATE_LIST = `${STRUCTURED_PRODUCTS_FROM_UNDERLYINGS}/LIST/UPDATE`;

export const TAG_ACTION_HISTORY = "HISTORY";
export const TAG_ACTION_TAG = "TAG";

export const structuredProductsFormConfigSet = (payload) => ({
  type: STRUCTURED_PRODUCTS_FORM_CONFIG_SET,
  payload,
});

export const structuredProductsFormOptionsSet = (payload) => ({
  type: STRUCTURED_PRODUCTS_FORM_OPTIONS_SET,
  payload,
});

export const structuredProductsFormOptionsDefaultSet = (payload) => ({
  type: STRUCTURED_PRODUCTS_FORM_OPTIONS_DEFAULT,
  payload,
});

export const loadStructuredProductsForm = () => ({
  type: STRUCTURED_PRODUCTS_FORM_LOAD,
});
export const destroyStructuredProductsForm = () => ({
  type: STRUCTURED_PRODUCTS_FORM_DESTROY,
});
export const loadStructuredProductsFormDataLoaded = (payload) => ({
  type: STRUCTURED_PRODUCTS_FORM_DATA_LOADED,
  payload,
});

export const submitStructuredProductsForm = (payload) => ({
  type: STRUCTURED_PRODUCTS_FORM_SUBMIT,
  payload,
});

export const structuredProductsRestoreCurrencyList = () => ({
  type: STRUCTURED_PRODUCTS_FORM_RESTORE_CURRENCY_LIST,
});

export const structuredProductsScheduleSet = (payload) => ({
  type: STRUCTURED_PRODUCTS_SCHEDULE_SET,
  payload,
});

export const structuredProductsScheduleUpdate = (payload) => ({
  type: STRUCTURED_PRODUCTS_SCHEDULE_UPDATE,
  payload,
});

export const scheduleOptionsUpdateFormFields = (payload) => ({
  type: STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_UPDATE_ALL_FIELDS,
  payload,
});

export const scheduleOptionsSet = (payload) => ({
  type: STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_SET,
  payload,
});

export const structuredProductsResponseScheduleSet = (payload) => ({
  type: STRUCTURED_PRODUCTS_SCHEDULE_RESPONSE_SET,
  payload,
});

export const scheduleOptionsLoader = (payload) => ({
  type: STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_LOADER,
  payload,
});

export const structuredProductsScheduleLoader = (payload) => ({
  type: STRUCTURED_PRODUCTS_SCHEDULE_LOADER,
  payload,
});

export const structuredProductsScheduleLock = (payload) => ({
  type: STRUCTURED_PRODUCTS_SCHEDULE_LOCK,
  payload,
});

export const structuredProductsScheduleFromHistory = (payload) => ({
  type: STRUCTURED_PRODUCTS_SCHEDULE_FROM_HISTORY,
  payload,
});

export const structuredProductsScheduleUserInputs = (payload) => ({
  type: STRUCTURED_PRODUCTS_SCHEDULE_USER_INPUTS,
  payload,
});

export const structuredProductsScheduleResetUserInputs = (payload) => ({
  type: STRUCTURED_PRODUCTS_SCHEDULE_USER_INPUTS_RESET,
  payload,
});

export const structuredProductsScheduleGetData = (payload) => ({
  type: STRUCTURED_PRODUCTS_SCHEDULE_GET_DATA,
  payload,
});

export const scheduleOptionGetData = (payload) => ({
  type: STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_GET_DATA,
  payload,
});

export const setStructuredProductsLoader = (payload) => ({
  type: STRUCTURED_PRODUCTS_FORM_LOADER,
  payload,
});

export const structuredProductsResultsLoader = (payload) => ({
  type: STRUCTURED_PRODUCTS_FORM_RESULTS_LOADER,
  payload,
});

export const structuredProductsReset = () => ({
  type: STRUCTURED_PRODUCTS_RESET,
});

export const structuredProductsResultsSet = (payload) => ({
  type: STRUCTURED_PRODUCTS_FORM_RESULTS_SET,
  payload,
});

export const structuredProductsFieldUpdate = (payload) => ({
  type: STRUCTURED_PRODUCTS_FORM_FIELD_UPDATE,
  payload,
});

export const structuredProductsFieldsSet = (payload) => ({
  type: STRUCTURED_PRODUCTS_FORM_FIELDS_SET,
  payload,
});

export const structuredProductsStructureSet = (payload) => ({
  type: STRUCTURED_PRODUCTS_STRUCTURE_SET,
  payload,
});

export const setCustomOptionsInitialValues = (payload) => ({
  type: STRUCTURED_PRODUCTS_SET_CUSTOM_OPTIONS_INITIAL_VALUES,
  payload,
});

export const setFieldsSettings = (payload) => ({
  type: STRUCTURED_PRODUCTS_SET_FIELD_SETTINGS,
  payload,
});

export const triggerRestoreByTagAction = (payload) => ({
  type: STRUCTURED_PRODUCTS_FORM_RESTORE_TRIGGER,
  payload,
});

export const restoreStructuredHistoryAction = (payload) => ({
  type: STRUCTURED_PRODUCTS_FORM_RESTORE,
  payload,
});

export const restoreFormValuesAction = (payload) => {
  return {
    type: STRUCTURED_PRODUCTS_FORM_VALUES_RESTORE,
    payload,
  };
};

export const restoreStructuredHistoryStartAction = () => ({
  type: STRUCTURED_PRODUCTS_FORM_RESTORE_START,
});

export const restoreStructuredHistoryProcessAction = (payload) => ({
  type: STRUCTURED_PRODUCTS_FORM_RESTORE_PROCESS,
  payload,
});

export const restoreStructuredHistoryFinishAction = () => ({
  type: STRUCTURED_PRODUCTS_FORM_RESTORE_FINISH,
});

export const restoreStructuredFormUnderlyingsRemoveAction = (payload) => ({
  type: STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_DELETE,
  payload,
});

export const restoreStructuredFormUnderlyingsUpdateAction = (payload) => ({
  type: STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_UPDATE,
  payload,
});

export const restoreStructuredFormUnderlyingsCreateListAction = (payload) => ({
  type: STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_CREATE_LIST,
  payload,
});

export const restoreStructuredFormUnderlyingsUpdateListAction = (payload) => ({
  type: STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_UPDATE_LIST,
  payload,
});

export const restoreStructuredFormUnderlyingsAddEmptyAction = () => ({
  type: STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_ADD_EMPTY,
});

export const restoreStructuredFormUnderlyingsResetAction = () => ({
  type: STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_RESET,
});

export const setStructureProductsFormStructures = (payload) => ({
  type: STRUCTURED_PRODUCTS_SET_FORM_STRUCTURES,
  payload,
});

export const bankHolidaysSet = (payload) => ({
  type: STRUCTURED_PRODUCTS_BANK_HOLIDAYS_SET,
  payload,
});

export const bankHolidaysGet = (payload) => ({
  type: STRUCTURED_PRODUCTS_BANK_HOLIDAYS_GET,
  payload,
});

export const setScheduleValidity = (payload) => ({
  type: STRUCTURED_PRODUCTS_SCHEDULE_INVALID,
  payload,
});
