import React from 'react';
import moment from 'moment';
import { FormattedNumber } from 'react-intl';
import { TYPE_LIMIT } from 'components/trade/constants.js';

const LegLabel = ({
  formattedUnit,
  notional,
  ticker,
  price,
  month,
  legs,
  orderType
}) => {
  /* this check is here to prevent the leg from displaying in the order summary modal if notional is 0 */
  const { expiryDate } = legs.find(({ contractExpiry }) => month === contractExpiry)
  return notional ? (
    <li>
      <FormattedNumber
        value={notional}
        minimumFractionDigits={0}
        maximumFractionDigits={4} />
      &nbsp;{formattedUnit}&nbsp;{ticker}&nbsp;@&nbsp;
      <FormattedNumber
        value={price}
        minimumFractionDigits={0}
        maximumFractionDigits={4} />
      &nbsp; - &nbsp;Expiry &nbsp;{moment(expiryDate).format('DD MMM YYYY')}
    </li>
  ) : null
};

export const TradeLabel = ({
  direction,
  legsPrices,
  formattedUnit,
  legs,
  orderType,
  price: cardPrice
}) => (
  <>
    <span className="text-bolder text-uppercase">Client {(direction === 'buyer' || direction === 'buy') ? 'Buys' : 'Sells'}</span>&nbsp;
    <ul>
      {legsPrices.map(({ notional, ticker, price, month }, index) => (<LegLabel
        key={index}
        formattedUnit={formattedUnit}
        notional={notional}
        ticker={ticker}
        price={orderType === TYPE_LIMIT ? cardPrice : price}
        month={month}
        legs={legs}
        orderType={orderType}
        />))}
    </ul>
  </>
);
