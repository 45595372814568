import { reducer as formReducer } from "redux-form";
import { PRICING_STRUCTURED_FORM_SHEDULE } from "pages/structured-products/components/forms/constants";
import {
  STRUCTURED_PRODUCTS_FORM_VALUES_RESTORE,
  STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_UPDATE_ALL_FIELDS,
  STRUCTURED_PRODUCTS_SET_CUSTOM_OPTIONS_INITIAL_VALUES,
} from "redux/actions/structured-products";
import { BOOKING_CALENDAR_FILL_ALL } from "redux/actions/booking";
import { BOOKING_FORM_CALENDAR } from "pages/booking/constants";

export default (formState, formAction) => {
  return formReducer.plugin({
    [formAction.payload?.formName]: (state, action) => {
      switch (action.type) {
        case STRUCTURED_PRODUCTS_SET_CUSTOM_OPTIONS_INITIAL_VALUES:
          return {
            ...state,
            initial: {
              ...state.initial,
              ...action.payload.initialValues,
            },
          };
        case STRUCTURED_PRODUCTS_FORM_VALUES_RESTORE:
          return {
            ...state,
            values: {
              ...state.values,
              ...action.payload.values,
            },
          };
        default:
          return state;
      }
    },
    [PRICING_STRUCTURED_FORM_SHEDULE]: (state, action) => {
      switch (action.type) {
        case STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_UPDATE_ALL_FIELDS:
          return {
            ...state,
            values: {
              ...state.values,
              ...action.payload,
            },
          };
        default:
          return state;
      }
    },
    [BOOKING_FORM_CALENDAR]: (state, action) => {
      switch (action.type) {
        case BOOKING_CALENDAR_FILL_ALL:
          return {
            ...state,
            values: {
              ...state.values,
              ...action.payload,
            },
          };
        default:
          return state;
      }
    },
  })(formState, formAction);
};
