import { STRUCTURE_FIXED_COUPON_NOTE, STRUCTURE_TWIN_WIN } from "constants.js";
import initial from "lodash/initial";
import isNull from "lodash/isNull";
import { dayWithoutWeekends } from "pages/price/PriceForm/forms/accumulator/validators";
import { useSelector } from "react-redux";
import { isDefined } from "validate.js";
import { spFormValueSelector, underlyingsSelector } from "components/configurableSp/selectors/selectors";
import { useQuery } from "react-apollo";
import {
  QUERY_SCHEDULE_CONFIG,
  QUERY_FLOATING_LEG_SCHEDULE_CONFIG,
  VALIDATE_FLOATING_SCHEDULE,
  VALIDATE_SCHEDULE,
} from "redux/queries/configurable-sp/form-data";
import { GRAPHQL_NO_CACHE } from "components/graphql/constants";
import { useMemo } from "react";
import { convertDataType } from "./v2/util";
import { DocumentNode } from "graphql";
import { ScheduleType } from "./v2/types";

export const scheduleRenderer = (formValues: any = {}, schedule: any = []) => {
  const { structure } = formValues || {};
  if (structure !== STRUCTURE_TWIN_WIN || !schedule?.length) return schedule;
  const { noCouponPaidAtMaturity } = formValues || {};
  const lastRow = schedule[schedule.length - 1];
  const lastRowModified = {
    ...lastRow,
    autocallTriggerLevel: null,
    couponTriggerLevel: !noCouponPaidAtMaturity
      ? lastRow.couponTriggerLevel
      : null,
  };
  return [...initial(schedule), lastRowModified];
};

export const getPaymentDelay = ({ paymentDelay = [] }: any) =>
  paymentDelay.length ? paymentDelay[0].value : 2;

export const SCHEDULE_OPTIONS_FIELDS = {
  STRIKE_DATE: "strikeDate",
  OBSERVATION_LAG: "observationLag",
  PAYMENT_LAG: "paymentLag",
};

export const SCHEDULE_OPTIONS_FIELDS_ARRAY = Object.values(
  SCHEDULE_OPTIONS_FIELDS,
);

export const scheduleOptions = (state) => {
  const {
    structuredProducts: {
      formOptionsDefault,
      scheduleOptions: scheduleOptionsOrigin,
      selectedStrucutre,
    },
  } = state;

  const options = {
    [SCHEDULE_OPTIONS_FIELDS.STRIKE_DATE]: dayWithoutWeekends(),
    [SCHEDULE_OPTIONS_FIELDS.OBSERVATION_LAG]:
      selectedStrucutre === STRUCTURE_FIXED_COUPON_NOTE ? 10 : 0,
    [SCHEDULE_OPTIONS_FIELDS.PAYMENT_LAG]: getPaymentDelay(formOptionsDefault),
  };

  if (!isNull(scheduleOptionsOrigin)) {
    options[SCHEDULE_OPTIONS_FIELDS.STRIKE_DATE] =
      scheduleOptionsOrigin[SCHEDULE_OPTIONS_FIELDS.STRIKE_DATE];
    options[SCHEDULE_OPTIONS_FIELDS.OBSERVATION_LAG] =
      scheduleOptionsOrigin[SCHEDULE_OPTIONS_FIELDS.OBSERVATION_LAG];
    options[SCHEDULE_OPTIONS_FIELDS.PAYMENT_LAG] =
      scheduleOptionsOrigin[SCHEDULE_OPTIONS_FIELDS.PAYMENT_LAG];
  }
  return options;
};

export const useGetSchedulerRequestConfig = (formName: string, isFloating = false, notificationErrorSimple) => {
  const formValues: any = useSelector((state) => {
    return spFormValueSelector(formName)(state);
  });

  const underlyings = useSelector(underlyingsSelector);
  const underlyingUrls = useMemo(()=> underlyings.map((u)=> u.uri), [underlyings])

  let query: DocumentNode | undefined;
  if (isFloating) {
    query = QUERY_FLOATING_LEG_SCHEDULE_CONFIG;
  } else {
    query = QUERY_SCHEDULE_CONFIG;
  }

  const { loading, data: configData, error } = useQuery(query, {
    fetchPolicy: GRAPHQL_NO_CACHE,
    variables: {
      structure: formValues?.structure?.value,
    },
    skip: !formValues?.structure?.value,
    onError: () => {
      const { description } = error?.graphQLErrors?.[0]?.message || '';
      notificationErrorSimple(description)
    }
  });

  const config =
    isFloating && configData?.getFloatingLegScheduleConfig
      ? configData?.getFloatingLegScheduleConfig?.config
      : configData?.getScheduleConfig?.config || {};

  const scheduleRequest = useMemo(() => {
    const scheduleRequestFields = Object.keys(config).filter(
      (f) => !f.includes("underlyings"),
    );
    if (scheduleRequestFields?.length && formValues) {
      const params: any = {};
      const missingFields: string[] = [];

      scheduleRequestFields.forEach((field) => {
        const dataType = config[field].dtype;
        const value = convertDataType(dataType, formValues[field]);

        if (isDefined(value)) {
          params[field] = value;
        } else {
          missingFields.push(field);
        }
      });
      params.underlyings = underlyingUrls;

      return missingFields.length === 0 && underlyingUrls.length > 0
        ? params
        : undefined;
    }
  }, [config, formValues, underlyingUrls]);

  return { scheduleRequestFieldsLoading: loading, scheduleRequest };
};

export const validateSchedule = async (
  client,
  scheduleType: string,
  schedule: any,
  secondarySchedule: any,
) => {

  let query: DocumentNode | undefined, variables, validationRequest;
  switch (scheduleType) {
    case ScheduleType.FLOATING:
      query = VALIDATE_FLOATING_SCHEDULE;
      variables = {
        floatingValidationParams: {
          floatingValidationRequest: {
            "floating-coupon-schedule": {
              schedule: {
                ...schedule
              }
            }
          }
        }
      };
      break;
    case ScheduleType.COUPON:
      query = VALIDATE_SCHEDULE;
      validationRequest = {
        "coupon": {
          schedule: {
            ...schedule
          }
        },
        "early-termination": {
          schedule: {
            ...secondarySchedule
          }
        }
      }

      variables = {
        validationParams: {
          validationRequest
        }
      };
      break;
    case ScheduleType.EARLY_TERM:
      query = VALIDATE_SCHEDULE;
      validationRequest = {
        "early-termination": {
          schedule: {
            ...schedule
          }
        },
        "coupon": {
          schedule: {
            ...secondarySchedule
          }
        }
      }
      variables = {
        validationParams: {
          validationRequest
        }
      };
      break;
  }

  let errorWhileValidating: string | undefined;

  const response = await client.query({
    query,
    variables,
    fetchPolicy: GRAPHQL_NO_CACHE,
  }).catch(err => {
    const { description } = err?.graphQLErrors?.[0]?.message || '';
    errorWhileValidating = description;
  }) 

  const { data } = response || {};
  const { validationErrors } = data?.[scheduleType === ScheduleType.FLOATING ? 'validateFloatingSchedule': 'validateSchedule'] || {}
  return { validationErrors: errorWhileValidating ? [errorWhileValidating] : validationErrors };
}
