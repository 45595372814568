import { thisMonth, nextMonth } from 'pages/price/PriceForm/forms/accumulator/validators.js';
import { CUSTOM_STRIPE_FORMS, PACKAGE_TYPE, VALID_PACKAGE_TYPES } from 'pages/booking/constants';
import { isBarriers, isFixingFrequency } from 'pages/booking/helpers';
export const STRUCTURE_ASIAN_OPTION = 'Asian Option';
export const STRUCTURE_ASIAN_SWAP = 'Asian Swap';
export const STRUCTURE_BULLET_OPTION = 'Bullet Option';
export const STRUCTURE_BULLET_SWAP = 'Bullet Swap';
export const STRUCTURE_FUTURE = 'Future';

export const ALLOW_BARRIER = [ STRUCTURE_ASIAN_OPTION, STRUCTURE_BULLET_OPTION ];
export const ALLOW_FOR_FREQUENCY = [ STRUCTURE_ASIAN_SWAP, STRUCTURE_ASIAN_OPTION ]

export const STRUCTURE_OPTIONS = [
  { label: STRUCTURE_ASIAN_SWAP, value: STRUCTURE_ASIAN_SWAP },
  { label: STRUCTURE_BULLET_SWAP, value: STRUCTURE_BULLET_SWAP },
  { label: STRUCTURE_FUTURE, value: STRUCTURE_FUTURE },
  { label: STRUCTURE_ASIAN_OPTION, value: STRUCTURE_ASIAN_OPTION },
  { label: STRUCTURE_BULLET_OPTION, value: STRUCTURE_BULLET_OPTION },
];

export const STRUCTURE_TO_LEG_TYPE = new Map([
  [STRUCTURE_ASIAN_OPTION, 'asian_vanilla'],
  [STRUCTURE_ASIAN_SWAP, 'asian_swap'],
  [STRUCTURE_BULLET_OPTION, 'vanilla'],
  [STRUCTURE_BULLET_SWAP, 'swap'],
  [STRUCTURE_FUTURE, 'future'],
]);

export const STRUCTURE_TO_PACKAGE_TYPE = new Map([
  [STRUCTURE_ASIAN_OPTION, PACKAGE_TYPE.OPTION],
  [STRUCTURE_ASIAN_SWAP, PACKAGE_TYPE.SWAP],
  [STRUCTURE_BULLET_OPTION, PACKAGE_TYPE.OPTION],
  [STRUCTURE_BULLET_SWAP, PACKAGE_TYPE.SWAP],
  [STRUCTURE_FUTURE, PACKAGE_TYPE.SWAP],
]);

export const BUSHELS_UNIT = 'Bushel';

export const BUY_DIRECTION = 'Buy';
export const SELL_DIRECTION = 'Sell';
export const DIRECTION_OPTIONS = [
  { value: BUY_DIRECTION.toLowerCase(), label: BUY_DIRECTION },
  { value: SELL_DIRECTION.toLowerCase(), label: SELL_DIRECTION }
];

export const VANILLA_TYPE_CALL = 'Call';
export const VANILLA_TYPE_PUT = 'Put';
export const VANILLA_TYPE_OPTIONS = [
  { value: VANILLA_TYPE_CALL, label: VANILLA_TYPE_CALL },
  { value: VANILLA_TYPE_PUT, label: VANILLA_TYPE_PUT }
];

export const FIXING_FREQUENCY_DAILY = 'Daily';
export const FIXING_FREQUENCY_WEEKLY = 'Weekly';
export const FIXING_FREQUENCY_OPTIONS = [
  { value: FIXING_FREQUENCY_DAILY, label: FIXING_FREQUENCY_DAILY },
  { value: FIXING_FREQUENCY_WEEKLY, label: FIXING_FREQUENCY_WEEKLY }
];

export const CUSTOM_STRIPE_DEFAULTS = new Map([
  [CUSTOM_STRIPE_FORMS.STRUCTURE, ({ types }) => ({
    structure: types[PACKAGE_TYPE.SWAP] < VALID_PACKAGE_TYPES[PACKAGE_TYPE.SWAP] ? STRUCTURE_ASIAN_SWAP : STRUCTURE_ASIAN_OPTION,
  })],
  [CUSTOM_STRIPE_FORMS.DETAILS, (_, { structure }) => ({
    strikeUnit: BUSHELS_UNIT,
    firstMonth: thisMonth(),
    lastMonth: nextMonth(),
    ...isBarriers(structure) ? {
      vanillaType: VANILLA_TYPE_CALL,
      barrierType: 'American Knock In',
    } : {},
    ...isFixingFrequency(structure) ? {
      fixingFrequency: FIXING_FREQUENCY_WEEKLY,
    } : {},
  })],
]);

export const DEFAULT_VOLUME = 10000;

