import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
import AutoComplete from 'components/form/inputs/autocomplete/AutoComplete';
import { mapNodes } from 'utils';
import isFunction from 'lodash/isFunction';
import { CLIENT_OPTIONS_QUERY } from './query';

const filterVariable = value => value ? [{ field: 'id', operator: 'eq', value }] : null;

const optionsList = (list = []) =>
  list.map(({ title: label, id: value }) => ({ label, value }));

const mapResponse = (respKey, isNodes = false, customOptionsList, types) => data => {
  const responseOptions = data[respKey] || [];
  const options = isNodes ? mapNodes(responseOptions) : responseOptions;
  return isFunction(customOptionsList) ? customOptionsList(options, types) : optionsList(options);
};

const mapClientsResponse = mapResponse('userClient', true);

const AccountField = props => {
  const {
    recordTypes = [],
    clientId = null,
    label = "Account",
    initialValues,
    change,
    form = null,
    isin = null,
    disabled = false,
    placeHolder = 'Please start typing the Account',
    bgLoaderOverlay = true,
  } = props;
  const [clientData, setClientData] = useState({ clientId });
  const [isFirstRequest, setFirstRequest] = useState(true);
  const [autoCompleteLoaded, setAutoCompleteLoaded] = useState({ customerId: true, locationId: true, traderId: true });
  const clientQueryVariables = (_, search) =>
    search ? ({ search, recordTypes }) : { recordTypes, isin };

  const onAutocompleteLoaded = (options = [], { name }) => {
    if (Array.isArray(options) && !options?.length) {
      const values = { ...autoCompleteLoaded, [name]: false }
      setAutoCompleteLoaded(values);
    }
    setFirstRequest(false);

    if(!clientData?.clientId && options.length && form) {
      const [{ value: firstId }] = options;
      change(form, "clientId", firstId)
    }
  }

  const onInputChangeValue = ({ name, value }) => {
    if (clientData[name] !== value) {
      const values = { ...clientData, [name]: value };
      setClientData(values);
    }
  }

  return (
    <Field
      disabled={disabled}
      name="clientId"
      label={label}
      placeHolder={placeHolder}
      bgLoaderOverlay={bgLoaderOverlay}
      queryVariables={clientQueryVariables}
      query={CLIENT_OPTIONS_QUERY}
      queryFilter={filterVariable(isFirstRequest && initialValues?.customerId)}
      mapResponse={mapClientsResponse}
      component={AutoComplete}
      onAutocompleteLoaded={onAutocompleteLoaded}
      onInputChangeValue={onInputChangeValue}
      clearable={true}
      selectedSingleOption={true}
      justValue={true}
      resetEmptyValue={false}
      formValues={{ clientId: clientData?.clientId }}
    />
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({ change }, dispatch);

export default compose(
  memo,
  connect(null, mapDispatchToProps),
)(AccountField);

