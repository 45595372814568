import { CARD_STATUSES } from "constants.js";
import { extractSwapContract } from "pages/price/output/selectors";
import { combineEpics, ofType } from "redux-observable";
import {
  minifutureCardUpdate,
  MINIFUTURE_CARD_FORM_OUTPUT,
  MINIFUTURE_CONFIRMATION_MODAL_APPROVE,
  MINIFUTURE_CONFIRMATION_MODAL_CANCEL,
} from "redux/actions/minifuture";
import { priceFormFailed } from "redux/actions/price";
import { notificationErrorSimple } from "redux/alerts/actions";
import { priceFormValueSelector } from "redux/epics/price/price-form";
import { cardError } from "redux/epics/price/structures/swap/";
import {
  getCardByFormName,
  getCardById,
} from "redux/epics/price/structures/swap/utils";
import { getSingleGraphQlError } from "redux/epics/utils";
import { updateCardMutation } from "redux/queries/minifuture";
import { from, merge } from "rxjs";
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
} from "rxjs/operators";
import { reset } from "redux-form";
import { changeAnyFormFieldActionFilter } from "utils/reduxFormSelector";
import { minifutureFormValuesSelector } from "pages/minifuture/form/Cards/PricingOutputCardForm";
const { REQUESTED } = CARD_STATUSES;

export const formFilter = (action) =>
  action &&
  action.meta &&
  !action.meta.form.indexOf(MINIFUTURE_CARD_FORM_OUTPUT);

export const trailPricesFilter = (state$) =>
  priceFormValueSelector(state$.value) &&
  state$.value.minifuture.trailPrice &&
  Array.isArray(state$.value.minifuture.trailPrice.cards);

const eventCardUpdate = (action$, state$, debounce = 50) =>
  merge(
    action$.pipe(
      filter(changeAnyFormFieldActionFilter("contractExpiry")),
      filter(formFilter),
      filter(() => trailPricesFilter(state$)),
      filter(({ payload }) => !!payload)
    ),
    action$.pipe(
      filter(changeAnyFormFieldActionFilter("clientDirectionFirst")),
      filter(formFilter),
      filter(() => trailPricesFilter(state$)),
      debounceTime(debounce),
      filter(({ payload }) => !!payload)
    ),
    action$.pipe(
      filter(changeAnyFormFieldActionFilter("clientDirectionSecond")),
      filter(formFilter),
      filter(() => trailPricesFilter(state$)),
      debounceTime(debounce),
      filter(({ payload }) => !!payload)
    ),
    action$.pipe(
      filter(changeAnyFormFieldActionFilter("quantity")),
      filter(formFilter),
      filter(() => trailPricesFilter(state$)),
      debounceTime(debounce),
      filter(
        ({ payload, meta }) =>
          !!payload &&
          meta &&
          parseFloat(payload) > 0
      )
    )
  );

const startCardUpdateEpic = (action$, state$) =>
  eventCardUpdate(action$, state$).pipe(
    map(({ meta }) => {
      const card = getCardByFormName(state$, meta, "minifuture");
      const isRequested = card.status === REQUESTED;
      return minifutureCardUpdate({
        ...card,
        isLoading: true, //Use to disable submit button
        confirmationPopup: isRequested,
      });
    })
  );

const sendUpdateRequest = (card) =>
  from(updateCardMutation(card)).pipe(
    map(({ price, direction, priceForCalcSpread, status }) => {
      const cardNew = {
        ...card,
        price,
        priceForCalcSpread,
        direction,
        status,
        isError: false
      };
      return minifutureCardUpdate(cardNew);
    }),
    catchError((error) => {
      const actions = [priceFormFailed(), minifutureCardUpdate({...card, isError: !!error})];
      const err = getSingleGraphQlError(error);
      if (err?.message) {
        return from([...actions, notificationErrorSimple(err.message)]);
      }
      return from([...actions, cardError(card, "update")]);
    })
  );

const isConfirmationPopup = (state$, meta) => {
  const card = getCardByFormName(state$, meta, "minifuture");
  return card?.confirmationPopup;
};

const cardUpdateEpic = (action$, state$) =>
  eventCardUpdate(action$, state$, 200).pipe(
    filter(({ meta }) => !isConfirmationPopup(state$, meta)),
    switchMap(({ meta, payload }) => {
      const card = getCardByFormName(state$, meta, "minifuture");
      const { quantity, bloombergTicker } =
        minifutureFormValuesSelector(card.id, state$.value) || {};
      let cardUpdated = {
        bloombergTicker,
      };
      switch (meta.field) {
        case "contractExpiry":
          const findContract = extractSwapContract({
            pricings: state$.value.minifuture.pricings,
            commodity: card.commodityContract,
          });

          cardUpdated = {
            ...card,
            ...cardUpdated,
            contractExpiry: payload,
            quotedCurrency: findContract.quotedCurrency,
          };
          break;
        case "quantity":
          cardUpdated = {
            ...cardUpdated,
            [meta.field]: parseFloat(quantity),
          };
          break;
        default:
          cardUpdated = {
            ...cardUpdated,
            [meta.field]: payload,
          };
      }
      return sendUpdateRequest({
        ...card,
        ...cardUpdated,
        loading: false,
        isLoading: false,
      });
    })
  );

const closeModalEpic = (action$, state$) =>
  action$.pipe(
    ofType(MINIFUTURE_CONFIRMATION_MODAL_CANCEL),
    switchMap(({ payload: id }) => {
      const card = getCardById(state$.value.minifuture.trailPrice.cards, id);
      const actions = [
        reset(`${MINIFUTURE_CARD_FORM_OUTPUT}_${id}`),
        minifutureCardUpdate({
          ...card,
          loading: false,
          isLoading: false,
          confirmationPopup: false,
        }),
      ];
      return from(actions);
    })
  );

const approveModalEpic = (action$, state$) =>
  action$.pipe(
    ofType(MINIFUTURE_CONFIRMATION_MODAL_APPROVE),
    switchMap(({ payload }) => {
      const card = getCardById(
        state$.value.minifuture.trailPrice.cards,
        payload
      );
      const { values } =
        state$.value.form[`${MINIFUTURE_CARD_FORM_OUTPUT}_${payload}`] || {};

      return sendUpdateRequest({
        ...card,
        ...values,
        loading: false,
        isLoading: false,
        confirmationPopup: false,
      });
    })
  );

export default combineEpics(
  startCardUpdateEpic,
  cardUpdateEpic,
  closeModalEpic,
  approveModalEpic
);
