import moment from 'moment';
import { combineEpics, ofType } from 'redux-observable';
import { extractSwapContract } from 'pages/price/output/selectors';
import { AUTH_LOGOUT } from 'redux/actions/auth';
import { pricingDataLoader, setLimitationForm, updateTrailPrice } from 'redux/actions/price';
import { loadedFxData, fxCardsUpdate, PRICING_FX_DATA_LOADED, PRICING_FX_FORM_LOAD, fxCommodityUpdateAction, PRICING_FX_COMMODITY_UPDATE } from 'redux/actions/fx';
import { loadFxData, subscribeFxCommodityContracts } from 'redux/queries/fx';
import { loadAllVariable } from 'redux/queries/price';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { from, merge } from 'rxjs';
import { filter, switchMap, takeUntil, map, distinctUntilChanged, bufferTime } from 'rxjs/operators';
import { LIMITATION_RELATION_SWAP } from 'redux/prices/config-swap';
import cardsEpics from './cards/';
import { priceFormDestroyFilter } from '../../filters';
import { STRUCTURE_FX, CARD_STATUSES, TRADE_DATE_FORMAT, BUFFER_TIMEOUT } from 'constants.js';
import { distinctObjectChanges } from 'redux/epics/utils';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import { formatSwapPrice } from 'redux/reducers/price';
import { formatDate2UI } from 'pages/price/PriceForm/utils';
import { notificationErrorSimple } from 'redux/alerts/actions';

export const fxTrailPricesFilter = state$ => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_FX && state$.value.fx.trailPrice && Array.isArray(state$.value.fx.trailPrice.cards);

export const loadFxForm = action$ =>
  action$
    .pipe(
      ofType(PRICING_FX_FORM_LOAD),
      switchMap(() => {
        return from(loadFxData())
          .pipe(
            takeUntil(action$.pipe(filter(
              action => action.type === AUTH_LOGOUT ||
                priceFormDestroyFilter(action)
            )))
          );
      }),
      switchMap(data => {
        const actions = [
          loadedFxData({ pricings: data }),
        ]
        return from(actions);
      })
    );

export const fxLifeEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_FX_DATA_LOADED),
      map(({ payload }) => payload && payload.pricings),
      filter(data => !!data),
      map(data => {
        const formData = priceFormValueSelector(state$.value);
        return loadAllVariable(formData, data, LIMITATION_RELATION_SWAP);
      }),
      map((limitation) => {
        return setLimitationForm({
          limitation,
          limitationRelation: LIMITATION_RELATION_SWAP
        })
      }),
    );

export const loadFxFinish = action$ =>
  action$.pipe(
    ofType(PRICING_FX_DATA_LOADED),
    switchMap(() => from([
      pricingDataLoader(false),
      updateTrailPrice(null)
    ]))
  );

export const extractContractData = (pricings, cardData) => {
    const find = extractSwapContract({
      pricings: pricings,
      commodity: cardData.commodity
    });

    return find ? {
      ...cardData,
      ...find,
    } : null;
  }

const fxSubscriptionEpic = (action$, state$) =>
  merge(
    action$.pipe(ofType(PRICING_FX_FORM_LOAD)),
  )
    .pipe(
      switchMap(() => {
        return merge(
          action$.pipe(ofType(PRICING_FX_DATA_LOADED)),
        ).pipe(
          switchMap(() => {
            return from(subscribeFxCommodityContracts()).pipe(
              filter(() => fxTrailPricesFilter(state$)),
              bufferTime(BUFFER_TIMEOUT),
              filter((data) => !!data.length),
              distinctUntilChanged(distinctObjectChanges),
              map((commodity) => {
                return fxCommodityUpdateAction(commodity);
              }),
              takeUntil(action$.pipe(filter(action => {
                return (priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure !== STRUCTURE_FX) ||
                  priceFormDestroyFilter(action)
              })))
            )
          }),
        )
      })
    );

const cardsSelector = createSelector(
  [
    state => state.cards,
    state => state.contract
  ],
  (cards, commodities) => {
    return Array.isArray(cards) && cards.map(card => compareCard(card, commodities));
  }
);

const compareCard = (card = {}, commodities) => {
  const rest = { ...card };

  const formattedCardTradeDate = moment(card.tradeDate).format(TRADE_DATE_FORMAT);

  const getCommodity = commodities.filter(({ data : { pricingFxContract }}) => {
    const formattedCommodityTradeDate = moment(pricingFxContract.tradeDate).format(TRADE_DATE_FORMAT);
    return pricingFxContract && card.fxCode &&
      card.fxCode.toLowerCase() === pricingFxContract.contract.toLowerCase() &&
      card.direction === pricingFxContract.direction &&
      card.notional === pricingFxContract.notional &&
      (card.baseCurrency !== card.notionalCurrency) === pricingFxContract.isContra &&
      moment(formattedCardTradeDate).isSame(formattedCommodityTradeDate)
  });

  if(!getCommodity.length) return rest;

  const commodity = getCommodity[getCommodity.length - 1]['data']['pricingFxContract'];

  const formattedCommodityTradeDate = moment(commodity.tradeDate).format(TRADE_DATE_FORMAT);
  if (
    commodity && card.fxCode &&
    card.fxCode.toLowerCase() === commodity.contract.toLowerCase() &&
    card.direction === commodity.direction &&
    card.notional === commodity.notional &&
    (card.baseCurrency !== card.notionalCurrency) === commodity.isContra &&
    moment(formattedCardTradeDate).isSame(formattedCommodityTradeDate) &&
    card.status === CARD_STATUSES.APPROVED
  ) {
    const quoteId = commodity.quoteId;
    const price = formatSwapPrice(commodity.price);
    const priceForCalcSpread = commodity.priceForCalcSpread
    if (card.price !== price || card.quoteId !== quoteId) return { ...rest, price, quoteId, priceForCalcSpread };
  }
  return rest;
}

export const updateCardsState = (cards, contract) => {
  return cardsSelector({
    cards,
    contract
  });
}

export const cardLiveUpdate = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_FX_COMMODITY_UPDATE),
      filter(() => priceFormValueSelector(state$.value)),
      filter(({ payload }) => {
        return !isEmpty(state$.value.fx.trailPrice && state$.value.fx.trailPrice.cards) && !isEmpty(payload);
      }),
      map(({ payload }) => payload),
      map((fxSubBufferArray) => {
        const cards = updateCardsState(state$.value.fx.trailPrice.cards, fxSubBufferArray);
        return fxCardsUpdate({
          ...state$.value.fx.trailPrice,
          cards
        });
      }),
    );

export const cardFxError = (card, action = 'delete') => {
  if (!card) {
    return notificationErrorSimple(`Can't delete card`);
  }
  const label = card?.fxContract ? ` ${card.fxContract}` : '';
  const date = formatDate2UI(card.tradeDate);
  const text = `Can't ${action} price for${label} - ${date}`;
  return notificationErrorSimple(text);
}

export default combineEpics(
  loadFxForm,
  loadFxFinish,
  fxLifeEpic,
  fxSubscriptionEpic,
  cardLiveUpdate,
  cardsEpics,
);
