import { createSelector } from "reselect";
import { getFormValues } from "redux-form";
import { isDefined } from "validate.js";
import { CommonConfigurableForm, ConfigurableOwnProps } from "../types";
import validateSp from "../validateSp";
import { getWrapperLayout } from "../utils";
import { Categorisation } from "@agile/common-types/build/configurableSp/types";
import { FORM_NAME } from "pages/structured-products/constants";
import { getRegisterFields } from "redux/epics/utils";

export const spFormValueSelector = (name: string) => (state) =>
  getFormValues(name)(state);

export const getStructureList = (_, props: ConfigurableOwnProps) => {
  return props.structureList || [];
};

export const getWrapperList = (_, props: ConfigurableOwnProps) => {
  return props.wrapperList || [];
};

export const getStructureMap = (_, props: ConfigurableOwnProps) => {
  return props.structuresMap || {};
};

export const selectedStructureSelector = (formName: string) =>
  // @ts-ignore
  createSelector(
    [getSelectedValueSelector(formName, "structure")],
    (selectedStructure: string | undefined) => {
      return selectedStructure;
    },
  );

export const getSelectedValueSelector = (formName: string, formField: string) =>
  createSelector(
    [getFormValues(formName)],
    (formValues: CommonConfigurableForm) => formValues?.[formField]?.value,
  );

export const initialValuesSelector = (formName: string) =>
  // @ts-ignore
  createSelector(
    [
      getStructureMap,
      getStructureList,
      selectedStructureSelector(formName),
      getWrapperList,
      getSelectedValueSelector(formName, "format"),
    ],
    (
      structuresMap,
      structures,
      selectedStructure = "",
      wrapperList,
      selectedWrapper,
    ) => {
      const structureData = structuresMap[selectedStructure];
      const layout = structureData?.layout || [];
      const wrapperLayout = getWrapperLayout(wrapperList, selectedWrapper);

      const initialValues = [...layout, ...wrapperLayout].reduce(
        (result, { id, componentConfig, defaultValue }) => {
          const { options, componentType } = componentConfig;
          if (componentType === "custom") {
            return result;
          }

          if (isDefined(defaultValue) && options) {
            result[id] = options.find(
              (option) =>
                option.value === defaultValue || option.label === defaultValue,
            );
          } else if (!isDefined(defaultValue) && options) {
            result[id] = options[0];
          } else if (isDefined(defaultValue)) {
            const parsedValue = parseInt(defaultValue);
            if (componentType === "date") {
              if (!isNaN(parsedValue)) {
                const date = new Date();
                date.setDate(date.getDate() + parsedValue);
                result[id] = date;
              }
            } else if (!isNaN(parsedValue) && componentType !== "text") {
              result[id] = parsedValue;
            } else {
              result[id] = defaultValue;
            }
          }
          return result;
        },
        {},
      );

      return {
        ...initialValues,
        structure: structures[0],
        format: wrapperList[0]
          ? {
              label: wrapperList[0]?.wrapperName,
              value: wrapperList[0]?.wrapperUri,
            }
          : undefined,
      };
    },
  );

const underlyingFieldsSelector = createSelector(
  [(state) => getRegisterFields(FORM_NAME, state)],
  (fields) =>
    fields
      ? Object.keys(fields).filter((field) => field.includes("underlying_"))
      : undefined,
);

export const underlyingsSelector = createSelector(
  [(state: any) => state?.structuredProducts?.underlyings || []],
  (allUnderlyings) => {
    return allUnderlyings.reduce((result, u) => {
      const uri = u?.value;
      const id = u?.id;
      if (uri) {
        result.push({
          uri,
          id,
          iceTicker: u.iceTicker,
          label: u.label,
          name: u.name,
        });
      }
      return result;
    }, []);
  },
);

export const fieldsSettingsSelector = createSelector(
  [(state: any) => state?.structuredProducts?.fieldsSettings],
  (fieldsSettings) => {
    return fieldsSettings || {};
  },
);

export const initialValidationRulesSelector = (formName: string) =>
  // @ts-ignore
  createSelector(
    [
      getStructureMap,
      getStructureList,
      selectedStructureSelector(formName),
      underlyingFieldsSelector,
      fieldsSettingsSelector,
    ],
    (
      structuresMap,
      structures,
      selectedStructure = "",
      underlyingFields,
      fieldsSettings,
    ) => {
      const structureData =
        structuresMap[selectedStructure || structures[0].value];
      const fieldValidationRules = structureData?.fieldValidationRules || [];

      const defaultRules = {
        notional: {
          presence: {
            message: "^Cannot be left blank",
          },
        },
      };

      const rules = fieldValidationRules.reduce(
        (result, { id, validation }) => {
          if (isDefined(validation)) {
            if (id === "payoff>underlying>underlying-id") {
              return result;
            } else if (
              structureData.layout?.find(
                (l) =>
                  l.categorisations.includes(Categorisation.Pricing) &&
                  l.id === id,
              )
            ) {
              result[id] = validation;
            }
          }
          return result;
        },
        { ...defaultRules },
      );
      if (fieldsSettings.settings) {
        Object.keys(fieldsSettings.settings).forEach((key) => {
          if (fieldsSettings.settings[key] === false) {
            rules[key] = {
              restricted: true,
            };
          }
        });
      }
      return validateSp(rules);
    },
  );
