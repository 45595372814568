import TradeModal from 'pages/price/mini/components/trade/TradeModal';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { REFERENCE_TYPES_OPTIONS } from '../constants';
import { ContractExpiry, ReferencePrice, StartEndDates } from '../fields/';
import { filterDayOfWeek, WEEK_DAYS } from '../validate';
import PricingMiniControls from 'pages/price/mini/components/pricinigMiniForm/PricingMiniControls';

const PricinigMiniFormFlexible = props => {
  const {
    id,
    isLoading = false,
    contractExpiries = [],
    referenceTypes = [],
    trailId,
    form,
    futTradingUnits,
    getReferencePrice,
    onChangeReferenceType,
    isDisabledReference,
    structureExpiredAtMax,
    flexible,
    type,
    selectedUnderlying,
    pricingCardId,
    pricingTemplateId,
    expiryAllowedWeekDays,
    formData,
    isFixings,
    bankHolidays,
    isCalculating,
    onStopReferencePrice,
    xmlType,
    useMsa
  } = props;
  const { scheduleDates = ' ' } = formData || { scheduleDates: ' ' };
  const { startDate, endDate } = selectedUnderlying || {};
  const filterExpiryDate = filterDayOfWeek(expiryAllowedWeekDays);

  return (
    <Fragment>
      <div className="form-card-mini-content w-100">
        <PricingMiniControls {...props} />
        <Row className="row-size-xs">
          <Col xs={12}>
            <div className="fileds-toggle-group">
              <ContractExpiry contractExpiries={contractExpiries} isLoading={isLoading} />
              <ReferencePrice
                id={id}
                isLoading={isLoading || isCalculating}
                referenceTypes={referenceTypes}
                form={form}
                getReferencePrice={getReferencePrice}
                onChangeReferenceType={onChangeReferenceType}
                isDisabledReference={isDisabledReference}
                isCalculating={isCalculating}
                onStopReferencePrice={onStopReferencePrice}
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="mt-auto w-100 form-card-mini-content form-card-mini-footer">
        <StartEndDates
          startDate={startDate}
          endDate={endDate}
          filterEndDate={filterExpiryDate}
          isFlexible={flexible || false}
          structureExpiredAtMax={structureExpiredAtMax}
          isLoading={isLoading}
          scheduleDates={scheduleDates}
          bankHolidays={bankHolidays}
        />
        <Row className="mb-1">
          <Col xs={6} className="pl-1 offset-md-6">
            <TradeModal
              pricingCardId={pricingCardId}
              commodityContractId={selectedUnderlying?.id}
              pricingTemplateId={pricingTemplateId}
              trailId={trailId}
              cardId={id}
              type={type}
              futTradingUnits={futTradingUnits}
              disabled={!selectedUnderlying || isLoading || !isFixings}
              xmlType={xmlType}
              useMsa={useMsa} />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

PricinigMiniFormFlexible.defaultProps = {
  contractExpiries: [],
  referenceTypes: REFERENCE_TYPES_OPTIONS,
  isLoading: false,
  selectedUnderlying: null,
  fields: [],
  isTrade: false,
  isFactSheet: true,
  flexible: false,
  expiryAllowedWeekDays: WEEK_DAYS,
};

PricinigMiniFormFlexible.propTypes = {
  contractExpiries: PropTypes.array.isRequired,
  referenceTypes: PropTypes.array.isRequired,
  structureExpiredAt: PropTypes.array.isRequired,
  miniAccumulatorCardGetReferencePrice: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  selectedUnderlying: PropTypes.object,
  trailId: PropTypes.string,
  tradeId: PropTypes.string,
  isTrade: PropTypes.bool.isRequired,
  isFactSheet: PropTypes.bool.isRequired,
  expiryAllowedWeekDays: PropTypes.array.isRequired,
};

export default PricinigMiniFormFlexible
