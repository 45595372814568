import { withBreakPointsContext } from 'components/theme/breakPoints/BreakPoints';
import { DEBOUNCE_TIME_TABS } from 'constants.js';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'redux';
import { mapNodes } from 'utils/';
import EmptyPricingHistory from './EmptyPricingHistory';
import './PricingHistory.css';
import PricingHistoryTableContent from './PricingHistoryTableContent';
import { PRICING_HISTORY_QUERY, PRICING_HISTORY_QUERY_OPTIONS } from './query';
import { SORT_FIELDS } from './sorter';

const filterUpdateDate = ({ key, value: { min, max } }) => [
  { field: key, operator: 'gte', value: min },
  { field: key, operator: 'lte', value: max }
];

const updateFilter = (filter, { key, value }, findIndex) => {
  if (isObject(value)) {
    const newDateFilter = filterUpdateDate({ key, value });
    const minIndex = filter.findIndex((el) => el.field === key && el.operator === newDateFilter[0].operator);
    const maxIndex = filter.findIndex((el) => el.field === key && el.operator === newDateFilter[1].operator);
    if (minIndex >= 0 && maxIndex >= 0) {
      filter[minIndex] = newDateFilter[0];
      filter[maxIndex] = newDateFilter[1];
    }
  } else {
    filter[findIndex].value = value;
  }

  return filter;
}

const filterUpdateSingle = ({ key: field, value, operator = 'eq' }) => [{ field, operator, value }];

const removeFromFilter = (newFilter, { key }) => newFilter.filter((el) => el.field !== key);

const filterValueUpdated = (field) => isObject(field.value) ? filterUpdateDate(field) : filterUpdateSingle(field);

export const createFilter = (filter, field = {}) => {
  let newFilter = filter ? cloneDeep(filter) : [];
  let { value } = field;
  // value = isString(value) ? value.toLowerCase() : value
  if (isNull(value) && field.key === 'ALL') {
    newFilter = [];
    return newFilter;
  }
  const findIndex = newFilter.findIndex((el) => el.field === field.key);
  if (isNull(value)) {
    newFilter = removeFromFilter(newFilter, field);
  } else if ((isBoolean(value) || !!value) && findIndex >= 0 && filter[findIndex]) {
    newFilter = updateFilter(newFilter, { ...field, value }, findIndex);
  } else {
    newFilter = [
      ...newFilter,
      ...filterValueUpdated({ ...field, value })
    ];
  }

  return !isEmpty(newFilter) ? newFilter : null;
}

class PricingHistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromFilter: false,
      orderBy: {
        order: 'desc',
        by: SORT_FIELDS[0].key
      },
      filter: [],
      isFirstRequest: true,
      isLoadingRefetch: false
    };
    this.scroller = React.createRef();
    this.refetch = debounce(this._refetch.bind(this), DEBOUNCE_TIME_TABS);
  }

  static getDerivedStateFromProps(props) {
    return !props.loading && props.calculateHistory ? {
      isFirstRequest: false
    } : null
  }

  onChangeFilter = field => {
    const filter = createFilter(this.state.filter, field);
    this.setState({
      fromFilter: true,
      filter
    });
    this.scrollTop();
    this.refetch();
  }

  onChangeOrder = field => {
    let { orderBy } = this.state;
    const order = (orderBy.by === field && orderBy.order === 'desc') ? 'asc' : 'desc';
    const newOrder = orderBy = {
      order: order,
      by: field
    };
    this.setState({ fromFilter: true, orderBy: newOrder });
    this.scrollTop();
    this.refetch();
  }

  _refetch = async (additional) => {
    const { variables, refetch } = this.props;
    const { filter, orderBy, isLoadingRefetch } = this.state;
    if (isLoadingRefetch) return;
    this.setState({
      isLoadingRefetch: true
    });
    try {
      let response = await refetch({
        ...variables,
        sorter: orderBy,
        filter,
        ...additional
      }).then(resp => {
        this.setState({
          isLoadingRefetch: false
        });
        return resp;
      });

      return response;
    } catch (e) {
      console.log(e);
      this.scrollTop();
    }
  }

  scrollTop = () => {
    if (this.scroller && this.scroller.current) {
      this.scroller.current.scrollTo(0, 0);
    }
  }

  close = () => {
    const { onClose } = this.props;
    onClose(false);
  }

  render() {
    const {
      calculateHistory,
      loading,
      error
    } = this.props;
    const { isFirstRequest, fromFilter } = this.state;
    const items = mapNodes(calculateHistory);
    if (error) throw new Error('Server Error');
    if (!loading && !fromFilter && Array.isArray(items) && !items.length) {
      return <EmptyPricingHistory onClose={this.close} />;
    } else if (!isFirstRequest && Array.isArray(items)) {
      return (
        <div ref={this.scroller}>
          <PricingHistoryTableContent {...this.state} {...this.props} onClose={this.close} onChangeFilter={this.onChangeFilter} onChangeOrder={this.onChangeOrder} />
        </div>
      )
    } else {
      return null;
    }
  }
}

export default compose(
  graphql(PRICING_HISTORY_QUERY, PRICING_HISTORY_QUERY_OPTIONS),
  withBreakPointsContext
)(PricingHistoryTable);
