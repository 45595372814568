import moment from 'moment';
import round from 'lodash/round';
import { from, merge, of } from 'rxjs';
import { change, getFormValues } from 'redux-form';
import { combineEpics, ofType } from 'redux-observable';
import apolloClient from 'apollo.js';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import { QUERY_MAX_GTD_DATE_QUERY, QUERY_ORDER_GET } from 'components/orders/swap/queries';
import { STATE_NAME as MF_STATE_NAME } from 'pages/minifuture/constants';
import { TIME_IN_FORCE_DAY, TIME_IN_FORCE_GTD, TYPE_ICEBERG, TYPE_MARKET, TYPE_STOP, TYPE_STOP_LIMIT, TYPE_LIMIT, TIME_IN_FORCE_GTC } from 'components/trade/constants.js';
import { SWAP_ORDERS_FORM } from 'components/trade/swap/SwapTrade';
import { ASIAN_SWAP_ORDERS_FORM } from 'components/trade/asianSwap/Trade';
import { BULLET_STRIP_ORDERS_FORM } from 'components/trade/bulletStrip/Trade';
import { VANILLA_ORDERS_FORM } from 'components/trade/vanilla/VanillaTrade';
import { FX_ORDERS_FORM } from 'components/trade/fx/FxTrade';
import { tommorowDayWithoutWeekends } from 'pages/price/PriceForm/forms/accumulator/validators.js';
import { orderSet, orderGetOptions, ORDERS_EDIT, ORDERS_INIT_CURRENT, ORDERS_UPDATE_OPTIONS } from 'redux/actions/orders';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { catchError, filter, map, switchMap, debounceTime } from 'rxjs/operators';
import { changeActionFilter } from 'utils/reduxFormSelector';
import { getCardById } from '../price/structures/swap/utils';
import { OPTIONS } from 'redux/reducers/orders.js';
import { STRUCTURE_FX } from 'constants.js';

export const getMinExpiryDate = (nextDay, tradeDate) => {
  const date = moment(tradeDate).format('YYYY-MM-DD')
  return moment(date).isSame(moment().format('YYYY-MM-DD')) ? null : nextDay;
}

const initTradeEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(ORDERS_INIT_CURRENT),
      map(({ payload }) => payload),
      filter(payload => payload && payload.id),
      // debounceTime(150), //Modal open animation delay
      switchMap(({
        id,
        stateName = 'price',
        remaining,
        totalSold,
        issueSize,
        fairValue,
        ccy,
        quantoValue,
        ratio,
      }) => {
        const swapCard = getCardById(state$.value[stateName].trailPrice.cards, id);
        const trade = {
          id,
          remaining,
          totalSold,
          issueSize,
          fairValue,
          ccy,
          quantoValue,
          ratio,
          ...swapCard,
          // Do NOT round the initial price of an FX trade
          price: (swapCard.structure.toLowerCase() === STRUCTURE_FX.toLowerCase()) ? swapCard.price : round(swapCard.price, 4),
        };
        return from([
          orderSet(trade),
        ]);
      }),
    );

const fetchMaxGtdDate = (cardId, structure) => {
  return apolloClient.query({
    query: QUERY_MAX_GTD_DATE_QUERY,
    fetchPolicy: GRAPHQL_NETWORK_ONLY,
    variables: {
      cardId, structure
    },
  })
    .then(({ data }) => data)
};


const getMaxGtdDate = (action$, state$) =>
  action$
    .pipe(
      ofType(ORDERS_INIT_CURRENT),
      map(({ payload }) => payload),
      filter(payload => payload && payload.id),
      switchMap(({ id, stateName = 'price' }) => {
        const {
          structure
        } = getCardById(state$.value[stateName].trailPrice.cards, id);

        return from(fetchMaxGtdDate(id, structure ? structure.toLowerCase() : null)).pipe(
          switchMap(data => {
            if (data && data.maxGtdValue) {
              return from([
                change(SWAP_ORDERS_FORM, 'maxExpiryDate', data.maxGtdValue),
                change(ASIAN_SWAP_ORDERS_FORM, 'maxExpiryDate', data.maxGtdValue),
                change(VANILLA_ORDERS_FORM, 'maxExpiryDate', data.maxGtdValue),
              ]);
            }
            return from([]);
          }),
          catchError(() => {
            return of(notificationErrorSimple(`Can't get max Good Till Date`));
          }),
        );
      }),
    );

const initMfOrderPopup = (action$, state$) =>
  action$
    .pipe(
      ofType(ORDERS_INIT_CURRENT),
      map(({ payload }) => payload),
      filter(({ id, stateName = null }) => id && stateName === MF_STATE_NAME),
      switchMap(() => from([
        orderGetOptions(OPTIONS.ISIN),
      ]))
    );

export const fetchOrder = id => {
  return apolloClient.query({
    query: QUERY_ORDER_GET,
    fetchPolicy: GRAPHQL_NETWORK_ONLY,
    variables: {
      id
    },
  })
    .then(({ data }) => {
      return data.getOrder;
    })
};

const orderEditEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(ORDERS_EDIT),
      map(({ payload }) => payload),
      filter(payload => payload && payload.id),
      switchMap(({ id }) => {
        return from(fetchOrder(id)).pipe(
          switchMap((order) => of(orderSet({ ...order, mode: 'edit' }))),
          catchError(() => {
            return of(notificationErrorSimple(`Can't get order`));
          }),
        );
      }),
    );


const onChangeMfStrike = (action$, state$) =>
  merge(
    action$.pipe(filter(changeActionFilter(SWAP_ORDERS_FORM, 'orderType'))),
    action$.pipe(filter(changeActionFilter(SWAP_ORDERS_FORM, 'clientId'))),
  ).pipe(
    debounceTime(200),
    switchMap(() => {
      const { structure } = state$.value.orders.current;
      if(structure.toLowerCase() === MF_STATE_NAME) return from([orderGetOptions(OPTIONS.ISIN)]);
      return from([]);
    })
  );

export const priceFormValueSelector = new Map([
  [SWAP_ORDERS_FORM, getFormValues(SWAP_ORDERS_FORM)],
  [ASIAN_SWAP_ORDERS_FORM, getFormValues(ASIAN_SWAP_ORDERS_FORM)],
  [VANILLA_ORDERS_FORM, getFormValues(VANILLA_ORDERS_FORM)],
  [FX_ORDERS_FORM, getFormValues(FX_ORDERS_FORM)],
  [BULLET_STRIP_ORDERS_FORM, getFormValues(BULLET_STRIP_ORDERS_FORM)]
])

const changeOrderType = (action$, state$) =>
  merge(
    action$.pipe(filter(changeActionFilter(SWAP_ORDERS_FORM, 'timeInForce'))),
    action$.pipe(filter(changeActionFilter(SWAP_ORDERS_FORM, 'orderType'))),
    action$.pipe(filter(changeActionFilter(ASIAN_SWAP_ORDERS_FORM, 'timeInForce'))),
    action$.pipe(filter(changeActionFilter(ASIAN_SWAP_ORDERS_FORM, 'orderType'))),
    action$.pipe(filter(changeActionFilter(BULLET_STRIP_ORDERS_FORM, 'orderType'))),
    action$.pipe(filter(changeActionFilter(VANILLA_ORDERS_FORM, 'timeInForce'))),
    action$.pipe(filter(changeActionFilter(VANILLA_ORDERS_FORM, 'orderType'))),
    action$.pipe(filter(changeActionFilter(FX_ORDERS_FORM, 'timeInForce'))),
    action$.pipe(filter(changeActionFilter(FX_ORDERS_FORM, 'orderType'))),
  ).pipe(
    switchMap((props) => {
      const { meta: { form, field } } = props
      const formData = priceFormValueSelector.get(form)(state$.value);
      const { timeInForce, orderType, expiryDate, maxExpiryDate, stopPrice, maxShow, price, isCombinedCard = false } = formData;
      const actions = [];

      if (orderType === TYPE_MARKET && timeInForce !== TIME_IN_FORCE_DAY) {
        actions.push(change(form, 'timeInForce', TIME_IN_FORCE_DAY));
      }

      if (([BULLET_STRIP_ORDERS_FORM, ASIAN_SWAP_ORDERS_FORM].includes(form) || isCombinedCard) && orderType === TYPE_LIMIT && timeInForce !== TIME_IN_FORCE_GTC) {
        actions.push(change(form, 'timeInForce', TIME_IN_FORCE_GTC));
      }

      if (orderType === TYPE_STOP) {
        if (!stopPrice) actions.push(change(form, 'stopPrice', price));
        if (field === 'orderType') actions.push(change(form, 'stopPrice', price));
      } else {
        actions.push(change(form, 'stopPrice', null));
      }

      if (orderType === TYPE_STOP_LIMIT) {
        if (stopPrice) actions.push(change(form, 'stopPrice', stopPrice));
        if (field === 'orderType') actions.push(change(form, 'stopPrice', null));
      }

      if (orderType === TYPE_ICEBERG) {
        if (!maxShow) actions.push(change(form, 'maxShow', maxShow));
      } else {
        actions.push(change(form, 'maxShow', null));
      }

      if (timeInForce === TIME_IN_FORCE_GTD) {
        const nextDayExcludeFx = moment(tommorowDayWithoutWeekends()).startOf('day').isSameOrBefore(moment(maxExpiryDate).startOf('day')) ? tommorowDayWithoutWeekends() : null;
        const nextDay = form === FX_ORDERS_FORM ? getMinExpiryDate(tommorowDayWithoutWeekends(), maxExpiryDate) : nextDayExcludeFx;
        if (!expiryDate) actions.push(change(form, 'expiryDate', nextDay));
      } else {
        actions.push(change(form, 'expiryDate', null));
      }

      return from(actions);
    }),
  );


const setFirstDefaultIsinEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(ORDERS_UPDATE_OPTIONS),
      debounceTime(200),
      map(({ payload }) => payload),
      filter(({ field, options }) => field === 'isin' && options?.length),
      switchMap(({ options }) => {
        const { clientId: swap } = priceFormValueSelector.get(SWAP_ORDERS_FORM)(state$.value) || {};
        const form = swap ? SWAP_ORDERS_FORM : VANILLA_ORDERS_FORM;
        const [{ value }] = options;
        return from([change(form, 'isin', value)]);
      }),
    );

export default combineEpics(
  initTradeEpic,
  orderEditEpic,
  changeOrderType,
  getMaxGtdDate,
  initMfOrderPopup,
  onChangeMfStrike,
  setFirstDefaultIsinEpic,
);
