import { TIME_IN_FORCE_DAY, TYPE_MARKET, TIME_IN_FORCE_GTC, TYPE_LIMIT } from 'components/trade/constants.js';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { createSelector } from 'reselect';
import { DATE_FORMAT_LABEL, DATE_FORMAT_LABEL_SHORT } from '../../../constants';

export const sortDates = (a, b) => Date.parse(a) - Date.parse(b);

export const prepareDates = createSelector(
  [
    state => state.dates,
    state => state.format,
  ],
  (array, format = DATE_FORMAT_LABEL) => {
    return array
      .sort(sortDates)
      .map(date => ({
        value: date,
        label: formatDate2UI(date, format),
      }));
  }
);

export const prepareDatesNoSort = createSelector(
  [
    state => state.dates,
    state => state.format,
  ],
  (array, format = DATE_FORMAT_LABEL) => {
    return array
      .map(date => ({
        value: date,
        label: formatDate2UI(date, format),
      }));
  }
);

export const prepareExpiryList = (array = [], format = DATE_FORMAT_LABEL) => {
  return array
    .map(({ expiry, contract: contractCode = null, bloombergTicker, strike: strikeLevel }) => ({
      value: expiry,
      label: formatDate2UI(expiry, format),
      bloombergTicker,
      contractCode,
      strikeLevel
    }));
};

export function classNames() {
  const classes = [];
  for (let i = arguments.length - 1; i >= 0; i--) {
    const argument = arguments[i];
    if (isObject(argument)) {
      for (let key in argument) {
        if (argument[key] && key !== 'undefined') {
          classes.push(key);
        }
      }
    }
  }

  return classes.join(' ') || null;
};

export const formatDate2UI = (date, format = DATE_FORMAT_LABEL_SHORT) => moment.utc(date).format(format);

export const filterOptions = (options = [], fieldObj, resultField) => {
  const { field, value } = fieldObj ? fieldObj : {};
  const optionsSet = options.reduce((filtered, option) => {
    if (((option[field] && value && option[field].toLowerCase() === value.toLowerCase()) || (!field && !value))
      && option[resultField]) {
      filtered.add(option[resultField]);
    }
    return filtered;
  }, new Set([]));
  return Array.from(optionsSet);
};

export const pickLabel = (value, mapObject) => {
  return value && mapObject && mapObject[value] ? mapObject[value] : value;
};

export const upperCaseFirstLetter = string => isString(string) ? string.charAt(0).toUpperCase() + string.slice(1) : string;

export const optionsProviderObj = records =>
  Array.isArray(records) ?
    records.map(data => ({ value: data, label: upperCaseFirstLetter(data) })) : [];

export const optionsProviderObjLabelValue = records =>
    Array.isArray(records) ?
      records.map(({label, value}) => ({ value: value, label: label })) : [];

export const optionsProviderRadioObj = (records) =>
  Array.isArray(records) ?
    records.map(data => ({ value: data ? 'yes' : 'no', label: data ? 'Yes' : 'No' })) : [];

export const optionsProvider = createSelector(
  [records => sortBy(records)],
  records => optionsProviderObj(records)
);

export const optionsProviderLabelValue = createSelector(
  [records => sortBy(records)],
  records => optionsProviderObjLabelValue(records)
);

export const optionsProviderNoSort = createSelector(
  [records => records],
  records => optionsProviderObj(records)
);

export const optionsProviderEnhaceLabel = (records, enhace) =>
  Array.isArray(records) && typeof enhace === 'function' ?
    uniqBy(sortBy(records).map((value) => ({ value, label: upperCaseFirstLetter(enhace(value)) })), 'value') : [];

export const optionsProviderLowerCaseObj = records => optionsProviderEnhaceLabel(records, upperCaseFirstLetter);

export const optionsProviderLowerCase = createSelector(
  [records => sortBy(records)],
  records => optionsProviderLowerCaseObj(records)
);

// @TODO: check if not duplicated function
export const getOptionLabelByValue = (val, options = []) => {
  const find = options.find(({ value }) => value === val);
  return find && find.label ? find.label : null;
};

export const optionsProviderRadio = createSelector(
  [records => records],
  records => optionsProviderRadioObj(records)
);

export const filterTimeInForceOptions = (options = [], orderType, isAsianOrCompoSwap = false) => options.map(item => {
  item.disabled = false;
  if (orderType === TYPE_MARKET && item.value !== TIME_IN_FORCE_DAY) item.disabled = true;
  if (isAsianOrCompoSwap && orderType === TYPE_LIMIT && item.value !== TIME_IN_FORCE_GTC) item.disabled = true;
  return item;
});

export const slugify = (string = '') => {
  return string && string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}
