import OpenTradesTableItemBlock from "components/tables/blocks/OpenTradesTableItemBlock";
import { RECORD_EMPTY, STRUCTURE_FX } from "constants.js";
import gql from "graphql-tag";
import React, { PureComponent } from "react";
import { FormattedDate } from "react-intl";
import { getSortLabel } from "./sorter";
import OtcOrderCancelModal from "./OtcOrderCancelModal"

const otcOrderColumns = [
  { name: "orderId", show: true, label: 'ID', filterType: 'num', alwaysShow: true },
  { name: "externalId", show: true, label: 'External ID', alwaysShow: true },
  { name: "state", show: true, label: 'State'},
  { name: "structure", show: true, label: 'Structure'},
  { name: "structureName", show: true, label: 'Structure Name'},
  { name: "ticker", show: true, label: 'Ticker'},
  { name: "expiryDate", show: true, label: 'Expiry', fmt: x => <FormattedDate value={x} timeZone='utc'/>, filterType: 'date'},
  { name: "notional", show: true, label: 'Notional', alwaysShow: true, filterType: 'num' },
  { name: "referencePrice", show: true, label: 'Price', alwaysShow: true, filterType: 'num' },
  { name: "ccy", show: true, label: 'Currency' },
  { name: "premium", show: true, label: 'Premium', filterType: 'num' },
  { name: "premiumPaymentDate", show: false, label: 'Premium Payment Date', fmt: x => <FormattedDate value={x} timeZone='utc'/>, filterType: 'date' },
  { name: "settlementType", show: true, label: 'Settlement Type' },
  { name: "clientId", show: false, label: 'Client ID' },
  { name: "workflowType", show: false, label: 'Workflow Type'},
  { name: "workflowStatus", show: false, label: 'Workflow Status'},
  { name: "underlying", show: true, label: 'Underlying'},
  { name: "user", show: true, label: 'User'},
  { name: "customer", show: true, label: 'Customer', props: ['id', 'name', 'code'], fmt: x => x.name, filterType: 'qry'},
  { name: "location", show: true, label: 'Location', props: ['id', 'name', 'code'], fmt: x => x.name, filterType: 'qry'},
  { name: "physicalId", show: true, label: 'Physical ID'},
  { name: "comment", show: true, label: 'Comment'},
  { name: "approvedBy", show: false, label: 'Approved By'},
  { name: "seenBy", show: false, label: 'Seen By'},
  { name: "rejectedBy", show: false, label: 'Rejected By'},
  { name: "bookedBy", show: false, label: 'Booked By'},
  { name: "strike", show: true, label: 'Strike', filterType: 'num'},
  { name: "min", show: true, label: 'Min', filterType: 'num'},
  { name: "max", show: true, label: 'Max', filterType: 'num'},
  { name: "fixingDays", show: true, label: 'Fixing Days', filterType: 'num'},
  { name: "accumulationLevel", show: true, label: 'Accumulation Level', filterType: 'num'},
  { name: "barrierLevel", show: true, label: 'Barrier Level', filterType: 'num'},
  { name: "remainderLevel", show: true, label: 'Remainder Level', filterType: 'num'},
  { name: "createdAt", show: true, label: 'Created', fmt: x => <FormattedDate value={x} timeZone='utc'/>, filterType: 'date' },
  { name: "updatedAt", show: true, label: 'Updated', fmt: x => <FormattedDate value={x} timeZone='utc'/>, filterType: 'date' },
];

const OTC_ORDERS_ITEM_FRAGMENT = gql`fragment OtcOrdersTableConnectionFragment on OTCOrderType {
  ${otcOrderColumns.map((x) => !x.props ? x.name : `${x.name} {
    ${x.props.join("\n")}
  }`).join("\n")}
}`;

interface Props {
  id: number;
  index: number;
  externalId: string;
  screenSize: string;
  state: string;
  structure: string;
  displayColumns: any;
  onSelect: (id: number) => void;
  onClickCancel: (id: number) => void;
}

class OtcOrdersTableItem extends PureComponent<Props> {
  static columns = otcOrderColumns;
  static fragments = { item: OTC_ORDERS_ITEM_FRAGMENT };

  onSelectItem = () => {
    if (!this.props.onSelect || typeof this.props.onSelect !== "function") return;
    this.props.onSelect(this.props.id);
  };

  onClickCancelItem = async () => {
    if (!this.props.onClickCancel || typeof this.props.onClickCancel !== "function") return;
    return await this.props.onClickCancel(this.props.orderId);
  };

  render() {
    const { id, displayColumns, state, structure } = this.props;
    const isCancel = (state === 'pending' && structure !== STRUCTURE_FX)  || state === 'active';
    return !id ? null : (
      <div data-index={`record-${this.props.index + 1}`} className="app-table-row" onClick={this.onSelectItem} >
        {otcOrderColumns
          .filter((x) => (displayColumns && displayColumns[x.name]) || otcOrderColumns.some(y => y.name === x.name && y.alwaysShow))
          .map((x) => (
            <OpenTradesTableItemBlock label={getSortLabel(x.name)} name={x.name} screenSize={this.props.screenSize} >
              <div className="float-right float-sm-none block-right">
                {this.props[x.name] ? (x.fmt ? x.fmt : y => y)(this.props[x.name]) : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          ))}
          {isCancel && <div className="cancel-order">
            <OtcOrderCancelModal handleCancelationOrder={this.onClickCancelItem} />
          </div>}
      </div>
    );
  }
}

export default OtcOrdersTableItem;
